/* eslint-disable react/no-danger */
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { actions, GET_DETAIL_PROJECT } from '@containers/Home/slice';
import { NextSeo } from 'next-seo';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectConfigLittlePlanetProject,
  selectConfigFirstSceneProject,
  selectFirstSceneMediaProject,
  selectGATagTrackingProject,
  selectFeatureImageProject,
  selectGATrackingProject,
  selectThumbnailProject,
  selectMetaDataProject,
  selectTitleProject,
  selectListScene,
  selectDetailProject,
} from '@containers/Home/selectors';
import { wrapper } from 'store';
import Script from 'next/script';
import {
  LIST_FAVICON_DEFAULT,
  LIST_LINK_STYLE_SHEET,
} from '@containers/Home/constants';
import { isServer } from '@utils/helper';

const Home = dynamic(() => import('containers/Home'), {
  loading: () => <span>Loading</span>,
});

function ExportContainer() {
  const router = useRouter();
  const dispatch = useDispatch();

  const title = useSelector(selectTitleProject);
  const listScene = useSelector(selectListScene);
  const metadata = useSelector(selectMetaDataProject);
  const thumbnail = useSelector(selectThumbnailProject);
  const detailProject = useSelector(selectDetailProject);
  const ga_tracking = useSelector(selectGATrackingProject);
  const feature_image = useSelector(selectFeatureImageProject);
  const first_scene = useSelector(selectConfigFirstSceneProject);
  const ggtag_tracking = useSelector(selectGATagTrackingProject);
  const first_scene_media = useSelector(selectFirstSceneMediaProject);
  const config_little_planet = useSelector(selectConfigLittlePlanetProject);

  const [isLoadedClient, setIsLoadedClient] = useState(false);

  const detailFirstScene = useMemo(
    () => listScene.filter(item => item.id === first_scene)[0] || undefined,
    [listScene, first_scene],
  );

  const images = useMemo(() => {
    const imgs: any = [];
    if (thumbnail?.src)
      imgs.push({
        url: thumbnail?.src,
        width: 800,
        height: 600,
        alt: thumbnail?.name,
      });

    if (feature_image)
      imgs.push({
        url: feature_image,
        width: 800,
        height: 600,
        alt: '',
      });

    if (first_scene_media?.src)
      imgs.push({
        url:
          first_scene_media?.src_thumb ||
          first_scene_media?.src_preview ||
          first_scene_media?.src,
        width: 800,
        height: 600,
        alt: first_scene_media?.name,
      });

    return imgs;
  }, [thumbnail, feature_image, first_scene_media]);

  const getData = useCallback(async () => {
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      dispatch(
        GET_DETAIL_PROJECT({
          id:
            params.tour ||
            process.env.TOUR_ID_0 ||
            process.env.NEXT_PUBLIC_TOUR_ID_0 ||
            'mbf30-tong',
          isPublic: true,
        }),
      );
      const listPostData = await fetch(
        `${process.env.NEXT_PUBLIC_SUBFOLDER}/post.json`,
      );
      const listPost: any = await listPostData.json();
      dispatch(actions.setListPostUsedOnTour(listPost));
      setIsLoadedClient(true);
    } catch (err) {
      console.log(err);
    } finally {
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isServer) getData();
  }, [getData]);

  useEffect(() => {
    if (listScene && listScene.length) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      let scene = detailFirstScene
        ? { ...detailFirstScene }
        : { ...listScene[0] };

      if (params.tour === detailProject?.slug && router.query.scene) {
        scene = {
          ...listScene.find(item => item.id === router.query.scene),
        } || {
          ...listScene[0],
        };
      }

      if (params.tour === detailProject?.slug && !!params.scene) {
        scene = { ...listScene.find(item => item.id === params.scene) } || {
          ...listScene[0],
        };
      }

      if (scene) {
        const hotspot = scene?.hotspots?.find(
          item => item.id === params?.hotspot,
        );

        if (hotspot) scene.init_hotspot = hotspot;

        // NOTE: add intro little planet for first scene loaded
        scene.config = scene?.config || {};
        scene.config = {
          ...scene.config,
          isLittlePlanet: config_little_planet?.enable || false,
          timeTransitionLittlePlanet:
            config_little_planet?.transition_time || 2,
        };
        dispatch(actions.setSceneSelected(scene));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailProject?.slug, listScene.length]);

  return (
    <>
      <NextSeo
        title={metadata?.title || title}
        description={metadata?.description || 'Panoee - Virtual Tour 360'}
        additionalLinkTags={
          metadata?.favicon
            ? [
                ...[
                  {
                    rel: 'shortcut icon',
                    href: metadata?.favicon,
                  },
                ],
                ...LIST_LINK_STYLE_SHEET,
              ]
            : [...LIST_FAVICON_DEFAULT, ...LIST_LINK_STYLE_SHEET]
        }
        openGraph={{
          type: 'website',
          url: process.env.NEXT_PUBLIC_BASE_URL + router.asPath,
          title: metadata?.title || title,
          description: metadata?.description || 'Panoee - Virtual Tour 360',
          images,
          site_name: metadata?.title || title || 'Panoee Tour',
          profile: {
            username: router.query.username as string,
          },
        }}
        facebook={{
          appId: process.env.NEXT_PUBLIC_FB_APP_ID || '2086263614924092',
        }}
      />
      {ga_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ga_tracking}`}
          />
          <Script
            id="gg-tag"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${ga_tracking}', {
                  page_path: window.location.pathname,
                });
              `,
            }}
          />
        </>
      )}
      {ggtag_tracking && (
        <>
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${ggtag_tracking}`}
          />
          <Script
            id="gg-tag-1"
            dangerouslySetInnerHTML={{
              __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${ggtag_tracking}');
              `,
            }}
          />
        </>
      )}

      {!!isLoadedClient && detailProject && (
        <Home key={detailProject?.slug} isExport />
      )}
    </>
  );
}

export const getStaticProps = wrapper.getStaticProps(() => async () => {
  return {
    props: {},
  };
});

export default ExportContainer;
