import { Type_RefFuncKrpano } from '@components/Krpano/types';
import { createGlobalState } from 'react-hooks-global-state';
import { I_CommentScene, I_PayloadCommentScene } from '../types';

export const DEFAULT_VALUE_ZOOM_KRPANO = { zoom: undefined };

export const DEFAULT_VALUE_VLOOKAT_KRPANO = { vlookat: undefined };

export const DEFAULT_VALUE_HLOOKAT_KRPANO = { hlookat: undefined };

export const DEFAULT_VALUE_COLLABORATION_MODE = {
  loading: false,
  krpanoRef: undefined,
  commentSceneSelected: null,
  isShowDialogCreateCommentScene: false,
  commentSceneTemporary: null,
};

export const DEFAULT_VALUE_TOUR_STATS = {
  isShowControlbar: true, // for theme
  btnDirectionActive: '',
};

export const DEFAULT_VALUE_TOUR_EVENT_STATS = {
  isLoaded: false,
  //
  idPostProjectSelected: '',
  visibleDialogPostProject: false,
  //
  idPostSceneSelected: '',
  visibleDialogPostScene: false,
  isSoundOnScene: false,
  //
  isVisibleMap: true,
  isVisibleFloorplan: true,
  isVisiblePopupIntro: undefined,
  isActiveModeMultipleStaging: false,
  //
  isShowHotspotGroup: undefined,
};

export const DEFAULT_VALUE_GLOBAL_STATE = {
  isVisibleDialogGuide: null,
  isVisibleDialogNavigate: null,
};

const { useGlobalState: useVLookatKrpano } = createGlobalState<{
  vlookat?: number;
}>(DEFAULT_VALUE_VLOOKAT_KRPANO);

const { useGlobalState: useHLookatKrpano } = createGlobalState<{
  hlookat?: number;
}>(DEFAULT_VALUE_HLOOKAT_KRPANO);

const { useGlobalState: useZoomKrpano } = createGlobalState<{ zoom?: number }>(
  DEFAULT_VALUE_ZOOM_KRPANO,
);

const { useGlobalState: useCollaborationMode } = createGlobalState<{
  loading: boolean;
  krpanoRef?: Type_RefFuncKrpano;
  commentSceneSelected?: I_CommentScene | null;
  isShowDialogCreateCommentScene?: boolean;
  commentSceneTemporary?: I_PayloadCommentScene | null;
}>(DEFAULT_VALUE_COLLABORATION_MODE);

const { useGlobalState: useTourStats } = createGlobalState<{
  stats: {
    isShowControlbar?: boolean;
    btnDirectionActive?: string;
  };
}>({
  stats: DEFAULT_VALUE_TOUR_STATS,
});

const { useGlobalState: useTourEventState } = createGlobalState<{
  state: {
    isLoaded?: boolean;
    idPostProjectSelected?: string;
    visibleDialogPostProject?: boolean;
    idPostSceneSelected?: string;
    visibleDialogPostScene?: boolean;
    isSoundOnScene?: boolean;
    isVisibleMap?: boolean;
    isVisibleFloorplan?: boolean;
    isVisiblePopupIntro?: boolean;
    isActiveModeMultipleStaging?: boolean;
    isShowHotspotGroup?: boolean;
  };
}>({
  state: DEFAULT_VALUE_TOUR_EVENT_STATS,
});

const { useGlobalState: useGlobalState } = createGlobalState<{
  isVisibleDialogGuide?: boolean;
  isVisibleDialogNavigate?: boolean;
}>(DEFAULT_VALUE_GLOBAL_STATE);

export {
  useTourStats,
  useZoomKrpano,
  useHLookatKrpano,
  useVLookatKrpano,
  useTourEventState,
  useCollaborationMode,
  useGlobalState,
};
