import {
  I_CallToActionConfigGlobal,
  I_FormCaptureConfigGlobal,
  I_CollaborationConfig,
  I_ConfigScenePlugin,
  I_ConfigThemeCustom1,
  I_ConfigThemeDefault,
  I_ControlbarConfig,
  I_FloorplanConfig,
  I_LogoConfig,
  I_MapConfig,
  I_ConfigThemeBaseKrpano,
  I_ConfigThemeBlank,
  I_ConfigThemeWall,
  I_ConfigThemeMBF,
  I_ConfigThemeMBFHienDai,
  I_ConfigThemeMBFQuaKhu,
  I_ConfigThemeMBFTuongLai,
} from './types';

export const LIST_TRANSITION_SCENES = [
  { label: 'none', value: 'NOBLEND' },
  { label: '3d transition', value: '3d' },
  { label: 'simple crossblending', value: 'BLEND(1.0, easeInCubic)' },
  { label: 'zoom blend', value: 'ZOOMBLEND(2.0, 2.0, easeInOutSine)' },
  { label: 'black-out', value: 'COLORBLEND(2.0, 0x000000, easeOutSine)' },
  { label: 'white-flash', value: 'LIGHTBLEND(1.0, 0xFFFFFF, 2.0, linear)' },
  { label: 'right-to-left', value: 'SLIDEBLEND(1.0, 0.0, 0.2, linear)' },
  { label: 'top-to-bottom', value: 'SLIDEBLEND(1.0, 90.0, 0.01, linear)' },
  { label: 'diagonal', value: 'SLIDEBLEND(1.0, 135.0, 0.4, linear)' },
  { label: 'circle open', value: 'OPENBLEND(1.0, 0.0, 0.2, 0.0, linear)' },
  { label: 'vertical open', value: 'OPENBLEND(0.7, 1.0, 0.1, 0.0, linear)' },
  { label: 'horizontal open', value: 'OPENBLEND(1.0, -1.0, 0.3, 0.0, linear)' },
  { label: 'elliptic + zoom', value: 'OPENBLEND(1.0, -0.5, 0.3, 0.8, linear)' },
] as const;

// time pause when user interact on screen
export const TIME_DELAY_PAUSE_AUTOPLAYSCENE = 5;

export const CONFIG_THEME_COLOR_DEFAULT: {
  basekrpano: I_ConfigThemeBaseKrpano['config'];
  default: I_ConfigThemeDefault['config'];
  custom1: I_ConfigThemeCustom1['config'];
  blank: I_ConfigThemeBlank['config'];
  wall: I_ConfigThemeWall['config'];
  mbf: I_ConfigThemeMBF['config'];
  mbf_hiendai: I_ConfigThemeMBFHienDai['config'];
  mbf_quakhu: I_ConfigThemeMBFQuaKhu['config'];
  mbf_tuonglai: I_ConfigThemeMBFTuongLai['config'];
} = {
  default: {
    primaryColor: '#ffffff',
    textColor: '#000000',
  },
  custom1: {
    primaryColor: '#D3015B',
    secondaryColor: '#F7C003',
    textColor: '#ffffff',
  },
  wall: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    autoOpenSceneListDesktop: true,
    autoOpenSceneListMobile: false,
  },
  basekrpano: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    autoOpenSceneList: true,
  },
  blank: {
    primaryColor: '#00000080',
    textColor: '#ffffff',
    isHideInfoTour: false,
  },
  mbf: {
    textColor: '#ffffff',
    primaryColor: '#D3015B',
    secondaryColor: '#F7C003',
  },
  mbf_hiendai: {
    textColor: '#ffffff',
    primaryColor: '#dab952',
    secondaryColor: '#31285b',
  },
  mbf_quakhu: {
    textColor: '#ffffff',
    primaryColor: '#dab952',
    secondaryColor: '#31285b',
  },
  mbf_tuonglai: {
    textColor: '#ffffff',
    primaryColor: '#dab952',
    secondaryColor: '#31285b',
  },
};

export const CONFIG_POLYGON_HOTSPOT_DEFAULT = {
  fillColor: '#ffffff20',
  fillColorHover: '#ffffff50',
  borderWidth: 3,
  borderColor: '#ffffff',
  borderColorHover: '#ffffff',
};

export const DEFAULT_VALUE_FLOORPLAN_CONFIG: I_FloorplanConfig = {
  isShowOnStart: false,
  bgColor: '#ffffff75',
  floorplan: {
    type: 'panel',
    position: 'left',
  },
  marker: {
    bgColor: '#007afe',
    borderColor: '#007afe',
  },
  radar: {
    isEnabled: false,
    bgColor: '#007afe40',
    borderColor: '#007afebf',
    borderWidth: 1,
    width: 48,
  },
};

export const DEFAULT_VALUE_MAP_CONFIG: I_MapConfig = {
  isEnabled: false,
  isShowOnStart: false,
  mapStyle: '',
  mapType: 'roadmap',
  map: {
    type: 'panel',
    position: 'left',
  },
  marker: {
    src: '',
  },
  radar: {
    isEnabled: false,
    bgColor: '#007afe40',
    borderColor: '#007afebf',
    borderWidth: 1,
    width: 48,
  },
};

export const DEFAULT_VALUE_LOGO_CONFIG: I_LogoConfig = {
  isEnabled: false,
  image: '',
  url: '',
  size: 180,
  position: 'topLeft',
};

export const COLOR_HOTSPOT_BG_DEFAULT = '#E54D42';
export const COLOR_HOTSPOT_ICON_DEFAULT = '#fff';

export const COLOR_POPUP_BG_DEFAULT = '#ffffff';
export const COLOR_POPUP_TEXT_DEFAULT = '#000000';

export const DEFAULT_CONFIG_SCENE_PLUGIN: I_ConfigScenePlugin = {
  pp_light: {
    enabled: false,
    exposure: 0,
    lights: 0,
    shadows: 0,
    filterrange: 60,
    masking: 1,
    quality: 7,
  },
  pp_sharpen: {
    enabled: false,
    strength: 0,
    quality: 5,
    range: 1.5,
  },
  lensflare: {
    enabled: false,
    exposure_bias: -1,
    sun: 0.5,
    glare: 0.2,
    r_ring: 0.25,
    ath: 0,
    atv: 0,
  },
};

export const DEFAULT_CONFIG_INSTRUCTOR_HOTSPOT = {
  width: 150,
  height: 300,
  smoothing: 0.294,
  threshold: 0.133,
  volume: 1,
  loop: true,
  paused_on_start: true,
  media: undefined,
  sound_360: true,
  mask: '#238E54' as const,
} as const;

export const CONFIG_FORM_CAPTURE_DEFAULT: I_FormCaptureConfigGlobal = {
  backgroundColor: '#fff',
  overlayColor: '#00000073',
  layout: {
    type: 'dialog',
  },
};

export const CONFIG_CALL_TO_ACTION_DEFAULT: I_CallToActionConfigGlobal = {
  layout: 'list',
  position: 'left',
  offsetLeft: '12',
  offsetRight: '12',
  offsetBottom: '165',
};

export const LIST_FAVICON_DEFAULT = [
  {
    rel: 'shortcut icon',
    href: `${process.env.NEXT_PUBLIC_ASSETS_URL}/static/favicon.png`,
  },
  {
    rel: 'apple-touch-icon',
    href: `${process.env.NEXT_PUBLIC_ASSETS_URL}/static/favicon_60.png`,
    sizes: '60x60',
  },
  {
    rel: 'apple-touch-icon',
    href: `${process.env.NEXT_PUBLIC_ASSETS_URL}/static/favicon_76.png`,
    sizes: '76x76',
  },
  {
    rel: 'apple-touch-icon',
    href: `${process.env.NEXT_PUBLIC_ASSETS_URL}/static/favicon_120.png`,
    sizes: '120x120',
  },
  {
    rel: 'apple-touch-icon',
    href: `${process.env.NEXT_PUBLIC_ASSETS_URL}/static/favicon_152.png`,
    sizes: '152x152',
  },
];

export const LIST_LINK_STYLE_SHEET = [
  {
    rel: 'manifest',
    href: `/manifest.json`,
  },
];

export const DEFAULT_VALUE_COLLABORATION_CONFIG: I_CollaborationConfig = {
  isEnabled: false,
  isPublic: true,
  collaborations: '',
};

export const DEFAULT_VALUE_CONTROLBAR_CONFIG: I_ControlbarConfig = {
  floorplan: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-project-diagram',
  },
  sound_project_on: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-volume',
  },
  sound_project_off: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-volume-mute',
  },
  auto_rotate_on: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-repeat',
  },
  auto_rotate_off: {
    isHide: false,
    type: 'icon',
    icon: 'fal fa-repeat',
  },
  home: { isHide: false, type: 'icon', icon: 'far fa-home' },
  auto_change_scene_on: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-pause',
  },
  auto_change_scene_off: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-play',
  },
  sound_scene_on: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-volume-up',
  },
  sound_scene_off: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-volume-off',
  },
  view_mode: { isHide: false, type: 'icon', icon: 'far fa-eye' },
  multi_staging: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-images',
  },
  gyro: { isHide: false, type: 'icon', icon: 'far fa-hurricane' },
  vr: { isHide: false, type: 'icon', icon: 'far fa-head-vr' },
  fullscreen: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-expand-arrows-alt',
  },
  map: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-map-marked-alt',
  },
  info_scene: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-info',
  },
  info_tour: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-file-alt',
  },
  group_auto_play: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-play',
  },
  view_mode_normal: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-mountains',
  },
  view_mode_little_planet: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-globe-asia',
  },
  view_mode_mirror: {
    isHide: false,
    type: 'icon',
    icon: 'far fa-globe',
  },
};

export const ID_KRPANO_CONTAINER = 'krpanoViewSnapshot';
