import { FlexRowCenter } from '@styles/global-styles';
import styled from 'styled-components';

export const LayoutWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .--open {
    z-index: 10;
    position: absolute;
    left: 0%;
    bottom: 50%;
    transform: translateY(-50%);
    background: var(--color-theme);
    width: 24px;
    height: 24px;
    ${FlexRowCenter};
  }
`;
