/* eslint-disable react/no-danger */
import { Button } from 'antd';
import dynamic from 'next/dynamic';
import { memo } from 'react';
import { DialogNavigateWrapper } from './styled';

import useNvaigateBetweenProject from '@containers/Home/hook/useNavigateBetweenProject';

const DialogContainer = dynamic(() => import('@components/DialogContainer'));

interface Props {
  width?: number | string;
  visible: boolean;
  isCloseable?: boolean;
  onCancel: () => void;
}

export const DialogNavigate = ({ visible, isCloseable, onCancel }: Props) => {
  const { onGoToHienTai, onGoToQuaKhu, onGoToTuongLai } =
    useNvaigateBetweenProject();

  return (
    <DialogContainer
      visible={visible}
      onCancel={onCancel}
      maskClosable={isCloseable}
      centered
      bodyStyle={{
        maxWidth: '840px',
        width: '100%',
        boxShadow: 'none',
        border: 'none',
      }}
      width="840px"
    >
      <DialogNavigateWrapper
        imgDesktop={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/ve-tau-2_1.png`}
        imgMobile={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/ve-tau-2-copy-1.jpg`}
      >
        <div className="body hide-scrollbar" onClick={onCancel}>
          <div className="--listing">
            <Button className="itemTicketEntrance" onClick={onGoToQuaKhu}>
              <img
                src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/loop.gif`}
                alt=""
              />
              <h5>Quá khứ</h5>
              <span>1993 - 2018</span>
            </Button>
            <Button className="itemTicketEntrance" onClick={onGoToHienTai}>
              <img
                src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/loop.gif`}
                alt=""
              />
              <h5>Hiện tại</h5>
              <span>2018 - 2023</span>
            </Button>
            <Button className="itemTicketEntrance" onClick={onGoToTuongLai}>
              <img
                src={`${process.env.NEXT_PUBLIC_ASSETS_URL}/static/loop.gif`}
                alt=""
              />
              <h5>Tương lai</h5>
              <span>∞</span>
            </Button>
          </div>
        </div>
      </DialogNavigateWrapper>
    </DialogContainer>
  );
};

export default memo(DialogNavigate);
