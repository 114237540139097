import styled from 'styled-components';
import { FlexRowCenter } from '@styles/global-styles';

export const DialogGuideWrapper = styled.div`
  &.popupGuide {
    ${FlexRowCenter};

    .--wrapper {
      background: rgba(0, 0, 0, 0.5);
      color: #fff;
      padding: 2.5rem 3rem;
      /* padding: 0; */
      backdrop-filter: blur(10px);
      flex-flow: column;
    }

    .itemGuide {
      /* width: 33.333%; */
      text-align: center;
      .--icon {
      }
      span {
      }
    }

    .--close {
    }

    .--title {
      text-align: center;

      h1 {
        font-weight: initial;
        color: #fff;
        line-height: 120%;
      }
      strong {
        display: block;
      }
    }

    .--content {
    }

    .--sliderWrapper {
      display: flex;
      gap: 1.5rem;
      justify-content: center;
      margin: 2.5rem 0;
    }

    .--buttons {
      display: flex;
      flex-flow: column;
      align-items: center;

      .btnSecondary {
        width: max-content;
        background: var(--color-blue);
        color: #fff;
      }

      .btnTxtLink {
        color: #fff;
      }
    }
    @media (max-width: 768px) {
      /* .itemGuide {
        width: 50%;
      } */
    }
  }
`;
