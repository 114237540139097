// import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { changeProjectAction } from '@containers/Home/slice';
import {
  DEFAULT_VALUE_TOUR_EVENT_STATS,
  DEFAULT_VALUE_TOUR_STATS,
  useHLookatKrpano,
  useTourEventState,
  useTourStats,
  useVLookatKrpano,
  useZoomKrpano,
} from '@containers/Home/global/TourStats';
import { useCallback, useMemo } from 'react';

const useNvaigateBetweenProject = () => {
  // const router = useRouter();
  const dispatch = useDispatch();

  const [, setTourStats] = useTourStats('stats');
  const [tourEventStats, setTourEventStats] = useTourEventState('state');
  const [, setZoomKrpano] = useZoomKrpano('zoom');
  const [, setlLookatKrpano] = useHLookatKrpano('hlookat');
  const [, setVlookatKrpano] = useVLookatKrpano('vlookat');

  const onChangeProject = useCallback(
    slug => {
      window.history.pushState(
        null,
        null,
        `${process.env.NEXT_PUBLIC_SUBFOLDER || ''}/?tour=${slug}`,
      );
      // router.push(
      //   {
      //     pathname: process.env.NEXT_PUBLIC_SUBFOLDER || '',
      //     query: { tour: slug },
      //   },
      //   undefined,
      //   { shallow: true },
      // );
      setTourEventStats({
        ...DEFAULT_VALUE_TOUR_EVENT_STATS,
        isSoundOnScene: tourEventStats.isSoundOnScene,
        isLoaded: tourEventStats.isLoaded,
      });
      setTourStats(DEFAULT_VALUE_TOUR_STATS);
      setlLookatKrpano(undefined);
      setVlookatKrpano(undefined);
      setZoomKrpano(undefined);
      dispatch(changeProjectAction({ slug }));
    },
    [
      dispatch,
      setTourStats,
      setZoomKrpano,
      tourEventStats,
      setlLookatKrpano,
      setVlookatKrpano,
      setTourEventStats,
    ],
  );

  const onGoToTongQuan = useCallback(
    () => onChangeProject(process.env.NEXT_PUBLIC_TOUR_ID_0 || 'mbf30-tong'),
    [onChangeProject],
  );
  const onGoToQuaKhu = useCallback(
    () => onChangeProject(process.env.NEXT_PUBLIC_TOUR_ID_1 || 'mbf30-qua-khu'),
    [onChangeProject],
  );
  const onGoToHienTai = useCallback(
    () =>
      onChangeProject(process.env.NEXT_PUBLIC_TOUR_ID_2 || 'mbf30-hien-tai'),
    [onChangeProject],
  );
  const onGoToTuongLai = useCallback(
    () =>
      onChangeProject(process.env.NEXT_PUBLIC_TOUR_ID_3 || 'mbf30-tuong-lai'),
    [onChangeProject],
  );

  return useMemo(
    () => ({
      onGoToTongQuan,
      onGoToQuaKhu,
      onGoToHienTai,
      onGoToTuongLai,
    }),
    [onGoToHienTai, onGoToQuaKhu, onGoToTongQuan, onGoToTuongLai],
  );
};

export default useNvaigateBetweenProject;
