/**
 *
 * DialogContainer
 *
 */
import React, { memo, CSSProperties } from 'react';
import { Modal } from 'antd';
import {
  DialogHeaderWrapper,
  DialogBodyWrapper,
  DialogFooterWrapper,
  DialogWrapper,
} from './styled';

interface Props {
  visible: boolean;
  title?: string;
  top?: number | string;
  width?: number | string;
  centered?: boolean;
  className?: string;
  maskClosable?: boolean;
  keyboard?: boolean;
  zIndex?: number;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  outsideChildren?: React.ReactNode;
  onOk?: () => void;
  onCancel?: () => void;
  height?: string;
  // idBody?: string;
  hiddenCloseButton?: boolean;
  // colorCloseIcon?: string;
  isShowMask?: boolean;
  maskStyle?: CSSProperties;
  bodyStyle?: CSSProperties;
  dialogContainerStyle?: CSSProperties;
  dialogContentStyle?: CSSProperties;
  children: React.ReactNode;
}

const DialogContainer = memo(
  ({
    title = undefined,
    visible = false,
    width = '650px',
    centered = false,
    className = '',
    zIndex = 100,
    maskClosable = false,
    keyboard = false,
    isShowMask = true,
    hiddenCloseButton = false,
    dialogContainerStyle = {},
    maskStyle = {},
    bodyStyle = {},
    dialogContentStyle = {},
    height = 'auto',
    children,
    footer,
    header,
    outsideChildren,
    onOk,
    onCancel,
    top = 0,
  }: Props) => {
    return (
      <Modal
        title={title}
        open={visible}
        footer={null}
        width={width}
        centered={centered}
        className={className}
        zIndex={zIndex}
        keyboard={keyboard}
        maskClosable={maskClosable}
        bodyStyle={bodyStyle}
        onOk={onOk}
        onCancel={onCancel}
        style={{ ...dialogContainerStyle, ...{ top: top || 0 } }}
        maskStyle={maskStyle}
        mask={isShowMask}
        destroyOnClose
      >
        <DialogWrapper style={dialogContentStyle}>
          {!hiddenCloseButton && (
            <div className="close" onClick={onCancel}>
              <i aria-hidden className="fal fa-times" />
            </div>
          )}
          {/* {!hiddenCloseButton && ( */}
          <DialogHeaderWrapper>{header}</DialogHeaderWrapper>
          {/* )} */}
          <DialogBodyWrapper style={{ height }}>{children}</DialogBodyWrapper>
          <DialogFooterWrapper>{footer}</DialogFooterWrapper>
        </DialogWrapper>
        {outsideChildren}
      </Modal>
    );
  },
);

export default DialogContainer;
