import styled from 'styled-components';

export const DialogNavigateWrapper = styled.div<{
  imgDesktop: string;
  imgMobile: string;
}>`
  background-image: url(${props => props.imgDesktop});
  background-size: 100% auto;
  background-repeat: no-repeat;
  height: 410px;

  .body {
  }

  .--listing {
    display: flex;
    gap: 2rem;
    position: absolute;
    top: 236px;
    left: 86px;
  }

  .itemTicketEntrance {
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: transparent;
    border: none;
    outline: none;
    color: #fff;
    flex-flow: column;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    transition: var(--transition-smooth);
    position: relative;
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
    }
    h5 {
      text-transform: uppercase;
      font-family: var(--font-title);
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0;
    }
    span {
      font-size: 0.9rem;
      opacity: 0.75;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  @media (max-width: 575px) {
    background-image: url(${props => props.imgMobile});
    height: 684px;
    background-size: 354px auto;
    background-position: center;
    .body {
    }
    .--listing {
      flex-wrap: wrap;
      justify-content: center;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
    .itemTicketEntrance {
      width: 150px;
      height: 150px;
    }
  }
`;
