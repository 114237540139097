import styled from 'styled-components';
import { FlexRowEnd } from '@styles/global-styles';

export const DialogWrapper = styled.div`
  height: 100%;
  width: 100%;
  font-size: 14px;
  .--wrapper {
    display: flex;
    position: relative;
    padding: 2rem;
    background-color: var(--color-bg);
    color: #fff;
    width: 100%;
  }

  .--title {
    h3 {
      font-size: 1.5rem;
    }
  }

  .--close {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    font-size: 2rem;
    width: 2rem;
    height: 2rem;
    z-index: 10;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.15s ease-out;
    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }
  /* .close {
    width: 2.5rem;
    height: 2.5rem;
    position: fixed;
    opacity: 1;
    transition: all 0.15s ease-out;
    z-index: 3;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    font-size: 24px;
    top: 4px;
    right: 4px;
    box-shadow: 0 2px 16px rgb(0 0 0 / 50%);
    color: #333;
    i {
      line-height: initial;
    }
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        transform: rotate(90deg);
      }
    }
  } */
`;

export const DialogHeaderWrapper = styled.div`
  position: relative;
  ${FlexRowEnd};
`;

export const DialogBodyWrapper = styled.div`
  width: 100%;
  height: 100% !important;
`;

export const DialogFooterWrapper = styled.div``;
