import { DefaultSeo } from 'next-seo';
import { NextWebVitalsMetric } from 'next/app';
import { GlobalStyle } from '@styles/global-styles';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { ResponsiveGlobal } from '@styles/responsive';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { onDisabledReactDevtoolOnProduction } from '@utils/helper';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { wrapper } from '../store';

import 'antd/dist/antd.css';

const Layout = dynamic(() => import('@components/Layout'));

const MyApp: React.FunctionComponent<AppProps> = ({
  Component,
  ...rest
}: AppProps) => {
  const router = useRouter();
  const { store, props } = wrapper.useWrappedStore(rest);

  useEffect(() => {
    onDisabledReactDevtoolOnProduction();
  }, []);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <DefaultSeo
        title="Panoee | The Smart Virtual Tour Software for everyone"
        description="Start free to build high-quanlity & smart 360° Virtual Tour in minutes with beautiful themes & unlimited customization."
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: router.pathname,
          site_name: 'Panoee',
          title: 'Panoee | The Smart Virtual Tour Software for everyone',
          description:
            'Start free to build high-quanlity & smart 360° Virtual Tour in minutes with beautiful themes & unlimited customization.',
        }}
        additionalMetaTags={[
          {
            property: 'category',
            content: 'Virtual Tour Software',
          },
          {
            property: 'name',
            content: 'Panoee Studio',
          },
          {
            name: 'application-name',
            content: 'Panoee',
          },
        ]}
      />
      <Provider store={store}>
        <Layout>
          <Component {...props.pageProps} />
        </Layout>
      </Provider>
      <GlobalStyle />
      <ResponsiveGlobal />
    </>
  );
};

export function reportWebVitals({
  id,
  name,
  // startTime,
  value,
  label,
}: NextWebVitalsMetric) {
  if ((window as any).gtag)
    (window as any).gtag('send', 'event', {
      eventCategory:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      eventAction: name,
      eventValue: Math.round(name === 'CLS' ? value / 1000 : value), // values must be integers
      eventLabel: id, // id unique to current page load
      nonInteraction: true, // avoids affecting bounce rate.
    });
}

export default MyApp;
